import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import temp from "../Images/celebs.jpg";
import { CrouselsSlider } from "../Api/Apis";
const Crousels = () => {
  const [banner, setBanner] = useState([]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    const loadData = () => {
      CrouselsSlider().then((res) => {
        console.log("response", res);
        setBanner(res?.data);
      });
    };
    loadData();
  }, []);

  return (
    <div>
      <Carousel
        responsive={responsive}
        arrows={true}
        swipeable={false}
        draggable={true}
        autoPlay={false}
        showDots={false}
        ssr={true}
        renderDotsOutside={true}
        infinite
        containerClass="carousel-container"
      >
        {banner?.map((value) => (
          <div>
            <img src={value?.image} alt="" className="layout1" />
          </div>
        ))}
      </Carousel>
      <div className="about_container">
        <h1 className="about_head">Artist</h1>
        <p className="about_para">
          Representatives of comedic artists, internet personalities, directors,
          writers, magicians, dancers, and more.
        </p>
      </div>
    </div>
  );
};

export default Crousels;
