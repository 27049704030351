import React, { useState, useEffect } from "react";
import Dp from "../Images/favicon.png";
import { ArtistBrand } from "../Api/Apis";
const Brands = () => {
  const [brand, setBrand] = useState([]);
  useEffect(() => {
    const loadData = () => {
      ArtistBrand().then((res) => {
        console.log("res", res);
        setBrand(res?.data);
      });
    };
    loadData();
  }, []);

  return (
    <div className="about_container">
      <h1 className="about_head">Brands</h1>
      <div className="brands-partner-container">
        {brand?.map((value) => {
          return (
            <div className="brands-partner">
              <img src={value?.logo} alt="" className="brand-logo" />
              <h4 style={{ fontSize: "16px", margin: "10px 0" }}>
                {value?.name}
              </h4>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Brands;
