import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer/Footer";
import { ArtistStatic } from "../../Api/Apis";

import { useParams } from "react-router-dom";

const Static = () => {
  let { slug } = useParams();

  const [statices, setStatices] = useState("");

  useEffect(() => {
    const loadData = () => {
      ArtistStatic(slug).then((res) => {
        const response = res;

        setStatices(response.data);
      });
    };
    loadData();
  }, [slug]);
  return (
    <>
      <Navbar />
      <div
        dangerouslySetInnerHTML={{ __html: statices?.content }}
        style={{ marginTop: "20%" }}
      />
      <Footer />
    </>
  );
};

export default Static;
