import React from "react";
import "../Footer/Footer.scss";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Link } from "react-router-dom";

function ChapterLinks({ chapters }) {
  return (
    <div className="container-fluid">
      <div className="row">
        {chapters.map((chapter) => {
          return (
            <div className="col-3 col-lg-1">
              <Link
                legacyBehavior
                to={`/chapters/${chapter.toLowerCase()}-chapter`}
                className="terms_word"
              >
                {chapter}
              </Link>
            </div>
          );
        })}
      </div>
      <style jsx>
        {`
          a {
            color: #ccc;
            font-weight: normal;
          }
          a:hover {
            text-decoration: unset;
            color: #fff;
          }
        `}
      </style>
    </div>
  );
}
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_icon">
        <TwitterIcon
          className="icon"
          onClick={() => window.open("https://twitter.com/kavishala")}
        />
        <FacebookIcon
          className="icon"
          onClick={() => window.open("https://facebook.com/kavishala.in")}
        />
        <InstagramIcon
          className="icon"
          onClick={() => window.open("https://instagram.com/kavishala.in")}
        />
        <WhatsAppIcon
          className="icon"
          onClick={() => window.open("http://whatsapp.kavishala.in/")}
        />
        <TelegramIcon
          className="icon"
          onClick={() => window.open("https://t.me/kavishalalabs")}
        />
        <MarkunreadIcon
          className="icon"
          onClick={() => window.open("mailto:admin@kavishala.in")}
        />
        <PinterestIcon
          className="icon"
          onClick={() => window.open("https://in.pinterest.com/kavishalaindia")}
        />
      </div>
      <div className="terms family">
        <ul className="terms_links">
          <li className="terms_linear">
            <a
              onClick={() => window.open("https://kavishala.in/about")}
              target="_blank"
              rel="noopener noreferrer"
              className="terms_word"
            >
              About Us
            </a>
          </li>
          <li className="terms_linear">
            <a
              onClick={() => window.open("https://kavishala.in/terms")}
              target="_blank"
              rel="noopener noreferrer"
              className="terms_word"
            >
              Terms &amp; Conditions
            </a>
          </li>
          <li className="terms_linear">
            <a
              onClick={() =>
                window.open("https://kavishala.in/privacy-and-policy")
              }
              target="_blank"
              rel="noopener noreferrer"
              className="terms_word"
            >
              Privacy Policy
            </a>
          </li>
          <li className="terms_linear">
            <a
              onClick={() => window.open("https://kavishala.in/Blog")}
              target="_blank"
              rel="noopener noreferrer"
              className="terms_word"
            >
              Blog
            </a>
          </li>
          <li className="terms_linear">
            <a
              onClick={() => window.open("https://kavishala.in/reviews")}
              target="_blank"
              rel="noopener noreferrer"
              className="terms_word"
            >
              Reviews
            </a>
          </li>
          <li className="terms_linear">
            <a
              onClick={() => window.open("https://kavishala.in/careers")}
              target="_blank"
              rel="noopener noreferrer"
              className="terms_word"
            >
              Careers
            </a>
          </li>
          <li className="terms_linear">
            <a
              onClick={() => window.open("https://kavishala.in/contact-us")}
              target="_blank"
              rel="noopener noreferrer"
              className="terms_word"
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
