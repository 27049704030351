import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Artist from "./Pages/DesktopPage/Artist";
import Static from "./Pages/DesktopPage/Static";
const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Artist />} />
          <Route path="/:slug" element={<Static />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
