import React from "react";
import Navbar from "../../Components/Navbar";
import Crousels from "../../Components/Crousels";
import Artists from "../../Components/Artists";
import ArtistContainer from "../../Components/ArtistContainer";
import EventUpcomig from "../../Components/EventUpcomig";
import Brands from "../../Components/Brands";
import Footer from "../../Components/Footer/Footer";
const Artist = () => {
  return (
    <div>
      <Navbar />
      <Crousels />
      <Artists />
      <ArtistContainer />
      <EventUpcomig />
      <Brands />
      <Footer />
    </div>
  );
};

export default Artist;
