import React, { useState, useEffect } from "react";
import { ArtistData } from "../Api/Apis";
const ArtistContainer = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const loadData = () => {
      ArtistData()
        .then((res) => {
          console.log("response", res);
          setData(res?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadData();
  }, []);
  return (
    <div className="about_container">
      <div className="row artist_main">
        {data?.map((value) => {
          return (
            <div
              className="col-3 item listBlock loaded"
              style={{
                visibility: "inherit",
                translate: "none",
                rotate: "none",
                scale: "none",
                transform: "translate(0px, 0px)",
                opacity: "1",
              }}
            >
              <div className="item_img">
                <img
                  className="desktop_img mobile_img"
                  src={value?.image}
                  data-src="https://oml.in/wp-content/uploads/2020/04/Adar-SNG1038-scaled-e1588697757316.jpg"
                />
              </div>
              <h3 className="artist_name">{value?.name}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ArtistContainer;
