import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ArtistContainer from "./ArtistContainer";
const Artists = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="artist_container">
      <Box
        sx={{ maxWidth: { xs: 320, sm: 1400 }, bgcolor: "background.paper" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className="main_tabs"
          //   classes={{ indicator: classes.indicator }}
        >
          <Tab label="All" className={value === 0 ? "tabs" : "tabselected"} />
          <Tab label="Actor" className={value === 1 ? "tabs" : "tabselected"} />
          <Tab
            label="Anchor"
            className={value === 2 ? "tabs" : "tabselected"}
          />
          <Tab
            label="Comedy"
            className={value === 3 ? "tabs" : "tabselected"}
          />
          <Tab
            label="Content Creator"
            className={value === 4 ? "tabs" : "tabselected"}
          />
          <Tab
            label="Director"
            className={value === 5 ? "tabs" : "tabselected"}
          />
        </Tabs>
      </Box>
    </div>
  );
};

export default Artists;
