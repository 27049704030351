import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Typography from "@mui/material/Typography";
import Temp from "../Images/celebs.jpg";
import "../Components/Chapter.scss";
import { ArtistEvents } from "../Api/Apis";
// import SkeletonCard from "components/Quiz/SkeletonCard";
import moment from "moment";

const EventUpcomig = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(12);
  const [page2, setPage2] = useState(24);
  const [word, setWord] = useState();

  useEffect(() => {
    const loadData = () => {
      ArtistEvents("open").then((response) => {
        if (response?.status === 200) {
          setData(response?.data);
        }
      });
    };
    loadData();
  }, []);
  // const Loader = () => {
  //   return Array(4)
  //     .fill()
  //     .map((e, i) => <SkeletonCard key={i} />);
  // };
  const handleMore = (pages) => {
    setPage2(page + pages);
    // Api.getAllEvents("open", page2)
    //   .then((res) => {
    //     setData(res?.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  return (
    <div className="about_container">
      <h1 className="about_head">Events</h1>
      <div className="chapter-main">
        {data?.map((value) => {
          return (
            <div className="chapter">
              <Card className="card_handler">
                <CardMedia
                  sx={{ height: 150 }}
                  image={value?.image}
                  title={"ram"}
                />
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="cardContentName"
                >
                  {value?.title}
                </Typography>
                <div className="events_date">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="cardDate"
                  >
                    <DateRangeIcon className="dateRange" />
                    {moment(value?.scheduled).format("MMM")}
                    {moment(value?.scheduled).format("DD")}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="cardTime"
                  >
                    <AccessTimeIcon className="dateRange" />
                    {moment(value?.scheduled).format("hh:mm a")}
                  </Typography>
                </div>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="cardLocation"
                >
                  <LocationOnIcon className="locationIcon" />
                  {value?.location}
                </Typography>
              </Card>
            </div>
          );
        })}
      </div>
      {data.length >= 12 ? (
        <div className="text-center my-3">
          <button
            className="btn btn-primary"
            onClick={() => {
              handleMore(page2);
            }}
          >
            Load more
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default EventUpcomig;
