import axios from "axios";
const base_url = "https://admin.kavishala.in";
const CrouselsSlider = () => {
  const urlPath = `/artistshala/carousel/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ArtistData = () => {
  const urlPath = `/artistshala/allartist/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ArtistEvents = (eventType) => {
  const urlPath = `/events/getopenandcloseevent/?event_status=${eventType}`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ArtistBrand = () => {
  const urlPath = `/artistshala/brandview/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ArtistStatic = (slug) => {
  const urlPath = `/artistshala/staticpages/${slug}/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
export { CrouselsSlider, ArtistData, ArtistEvents, ArtistBrand, ArtistStatic };
